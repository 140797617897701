var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"b79efa6832663a41d76a7d5a6293f1c612421917"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "/featureos/app-assets";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN
Sentry.init({
  enabled: process.env.NODE_ENV === 'production',
  dsn: SENTRY_DSN,
  tracesSampleRate: 0.1,
  profilesSampleRate: 0.1,
  environment: process.env.NEXT_PUBLIC_APP_ENV || process.env.NODE_ENV,
  integrations: [new Sentry.BrowserProfilingIntegration()],
  ignoreErrors: [
    'Non-Error promise rejection captured ',
    // Chunk Load Errors with regex
    /Loading chunk [\d]+ failed/,
    /Cannot\sread\sproperties\sof\sundefined\s\(reading\s'locale'\)/,
    "Cannot read properties of undefined (reading 'locale')",
    // Ethereum Provider Errors
    /MetaMask/,
    /Invariant: attempted to hard navigate to the same URL/,
    // sendObjectBeacon
    /sendObjectBeacon/,
    // This is safe to ignore as per: https://stackoverflow.com/a/50387233
    /ResizeObserver loop limit exceeded/,
    /is not an object (evaluating 'e.getInitialProps')/,
    /TypeError: Load failed/,
    /Failed to execute 'removeChild' on 'Node':/,
  ],
})
