import { useRouter } from 'next/router'
import React, { useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'

import { Dialog, DialogContent } from '@/components/shared/ui/Dialog/Dialog'
import UserPostSingle from '@/components/users/screens/UserPostSingle'

import AdminSinglePost from './AdminSinglePost'

export default function AdminSinglePostWrapper() {
  const router = useRouter()
  const [open, setOpen] = useState(false)
  const isAdmin = router.pathname.includes('/admin')
  const currentOpenState = useRef(open)
  const lastURL = useRef(router.asPath)

  useEffect(() => {
    if (
      router.pathname === '/p/[postSlug]' ||
      router.pathname === '/admin/p/[postSlug]' ||
      router.pathname === '/postwidget/b/[slug]/p/[postSlug]' ||
      router.pathname === '/embed/b/[slug]/p/[postSlug]' ||
      router.pathname === '/embed/home/p/[postSlug]' ||
      router.pathname === '/embed/p/[postSlug]' ||
      router.pathname === '/p/[postSlug]/review_vote_on_behalf/[voteKey]' ||
      router.pathname.includes('/widget')
    )
      return
    if (router.query.postSlug) {
      setOpen(true)
    } else {
      setOpen(false)
    }
  }, [router.query.postSlug])

  useEffect(() => {
    if (!open && currentOpenState.current) {
      router.push(lastURL.current, undefined, { shallow: true })
    }
    currentOpenState.current = open
  }, [open])

  useEffect(() => {
    if (window.location.pathname.includes('/admin/p/')) return
    if (window.location.pathname.includes('/p/')) return
    lastURL.current = window.location.pathname + window.location.search
  }, [router.pathname, router.query])

  const container =
    typeof window !== 'undefined'
      ? document.getElementById('main-section')
      : undefined

  if (isAdmin) {
    return open ? (
      ReactDOM.createPortal(
        <AdminSinglePost onClose={() => setOpen(false)} isModal />,
        document.getElementById('main-section') as HTMLElement
      )
    ) : (
      <></>
    )
  }

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogContent
        container={container}
        size='xl'
        className='! bg-gray3 !p-0'
      >
        <UserPostSingle />
      </DialogContent>
    </Dialog>
  )
}
